import * as React from 'react'
import AddToCalendar from '@culturehq/add-to-calendar'

const CalendarButton = ({
  id,
  title,
  startAt,
  endAt,
}) => {
  const [dropdown, setDropdown] = React.useState(
    document.querySelector('.chq-atc--dropdown')
  )

  React.useEffect(() => {
    if (dropdown) {
      dropdown.querySelectorAll('a').forEach((node) => {
        node.setAttribute('class', `${id}`)
      })
    }
  }, [dropdown])
  
  const getDropdown = () => {
    setDropdown(document.querySelector('.chq-atc--dropdown'))
  }

  const listenToBtns = (e) => {
    setTimeout(getDropdown, 500)
  }
  return  (
    <AddToCalendar
      children={
        <small
          onClick={(e) => listenToBtns(e)}
          className="d-block ms-1"
        >
          Add to Calendar
        </small>
      }
      filename={title}
      event={{
        name: title,
        details: 'BE @ PENANG 2023',
        location: 'Setia SPICE Convention Centre • Penang, Malaysia',
        startsAt: startAt,
        endsAt: endAt,
      }}
    />
  )
}

export default CalendarButton
