import * as bootstrap from 'bootstrap'
import Swiper, { Autoplay, Navigation, Pagination, EffectCoverflow } from 'swiper'

export const initHomeSwiper = () => {
  if (!document.querySelector('#topicSwiper')) return

  console.log('initTopicSwiper')
  const swiper = new Swiper('#topicSwiper', {
    modules: [Navigation],
    grabCursor: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 5,
      },
    },
  })
}

export const initHighlightSwiper = () => {
  if (!document.querySelector('#highlightSwiper')) return

  console.log('initHighlightSwiper')
  const swiper = new Swiper('#highlightSwiper', {
    modules: [Navigation, Pagination],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    grabCursor: true,
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    },
    centeredSlides: true,
  })
}