
require("@rails/ujs").start();
require("turbolinks").start();
require("jquery"); // for cocoon
require("@nathanvda/cocoon"); // for nested forms

import "@popperjs/core";
import * as bootstrap from "bootstrap";

import "../stylesheets/application";
import 'swiper/swiper-bundle.css'

import axios from 'axios'
window.axios = axios

// StimulusJS
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { initHomeSwiper, initHighlightSwiper } from "./shared_helpers";
const application = Application.start();
const context = require.context("./../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

// react_rails gem setup
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Bootstrap - enable tooltips and popovers
document.addEventListener("DOMContentLoaded", function (event) {
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
  initHomeSwiper()
  initHighlightSwiper()
});

